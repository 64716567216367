import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Box, Grid, Typography, Icon, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { withIsMobile, withRouter, StringUtil, ColorUtil } from 'utils';
import { LoadingView } from 'components';
import { StoreUtil, LocationTypeUtil } from 'doctivity-shared/utils';
import { ProviderCommentsDialog } from 'components';
import { upsertProviderComment } from 'store/actions/providerCommentsActions';
import { showConfirmationDialog } from 'store/actions/systemActions';
import {
  ListItemActionButton,
  ListItemLink,
  DetailContainer,
} from './ListItemDetail';
import AnalyticsCard from './AnalyticsCard';
import {
  loadFavoriteProviders,
  loadRecentProviders,
  loadProviderTopDx,
  loadProviderTopPx,
  loadProviderTopServiceLines,
} from 'store/actions/dashboardActions';

const styles = (theme) => ({
  listItemRow: {
    border: '1px solid #E0E0E0',
    '&:hover': {
      background: '#F5F5F5',
    },
  },
  affiliatedIcon: {
    fontSize: '14px',
    verticalAlign: 'text-top',
    color: '#2082F4',
    marginTop: '3px',
    marginRight: '2px',
    width: '14px',
    height: '14px',
    paddingRight: '4px',
  },
  favoriteIcon: {
    fontSize: '16px',
    verticalAlign: 'text-top',
    color: '#E300B8',
    marginRight: '10px',
    marginTop: '2px',
    width: '16px',
    height: '15px',
  },
  favoriteHeart: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '& > span': {
      fontSize: '72px',
      color: '#E300B8',
      width: '100%',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },

    [theme.breakpoints.down('md')]: {
      '& > span': {
        fontSize: '32px',
      },
    },
  },
  providerName: {
    color: '#333333',
    fontSize: '14px',
  },
  credential: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  specialtyContainer: {
    flexShrink: 1,
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      flexBasis: '35%',
    },
    overflow: 'hidden',
  },
  providerSpecialty: {
    overflow: 'hidden',
    fontSize: '12px',
    color: '#333',
    fontWeight: 300,
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
    },
  },
  locationContainer: {
    flexBasis: '45%',
    flexGrow: 0,
    flexShrink: 0,
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
      textOverflow: 'ellipsis',
    },
  },
  rowRoot: {
    background: 'linear-gradient(90deg, white 49%, #EBEBEB 50%)',
    cursor: 'pointer',
  },
  rowRootEmpty: {
    cursor: 'unset',
  },
  analyticsContainer: {
    background: '#FFF',
    boxShadow: '0px 0px 10px #0000001F',
    borderRadius: '10px',
    padding: '10px 15px',
    height: '464px',
  },
  noFavorites: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    backgroundColor: '#ebebeb',
    color: '#707070',
    fontFamily: 'Roboto',
    '& > p > h5': {
      color: '#333',
      fontSize: 14,
    },
    fontSize: 14,
    [theme.breakpoints.up('lg')]: {
      paddingTop: '20px',
    },
    display: 'flex',
    flexDirection: 'column',
    height: '25rem',
    justifyContent: 'space-between',
  },
  noFavoritesMessage: {
    fontSize: 14,
    paddingTop: theme.spacing(3),
    width: '100%',
    textAlign: 'center',
  },
});
function groupBy(callback, thisArg) {
  var obj = {};
  thisArg.forEach(function (value, idx, self) {
    var _a;
    var ret = thisArg
      ? callback.call(thisArg, value, idx, self)
      : callback(value, idx, self);
    ((_a = obj[ret]) !== null && _a !== void 0 ? _a : (obj[ret] = [])).push(
      value,
    );
  });
  return obj;
}
class DashboardProvidersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProvider: 0,
      selectedTopCodesType: 'dx',
      newNoteDialogOpen: false,
      topCodes: [],
      categories: [],
      series: [],
    };
    this.scrollRef = createRef();
  }

  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.favoriteProviders)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.app.selectedClient !== prevProps.app.selectedClient) {
      this.fetchData();
    }

    if (
      !StoreUtil.isLoaded(prevProps.favoriteProviders) &&
      StoreUtil.isLoaded(this.props.favoriteProviders)
    ) {
      this.fetchSelected();
    }
    if (
      this.state.selectedProvider !== prevState.selectedProvider ||
      (this.state.series?.length === 0 &&
        this.props.favoriteProviders?.data?.length > 0) ||
      this.props.dashboard != prevProps.dashboard ||
      this.state.selectedTopCodesType !== prevState.selectedTopCodesType
    ) {
      const { dashboard } = this.props;
      if (!this.props.favoriteProviders.data?.length) {
        return;
      }
      let selectedFavoriteProvider =
        this.props.favoriteProviders.data[this.state.selectedProvider];
      let newCategories = [];
      let newSeries = [
        // the order of these can NOT change it matches the id numbers for location_type
        {
          name: 'Ambulatory Surgery Center',
          data: [],
        },
        {
          name: 'Outpatient',
          data: [],
        },
        {
          name: 'Office',
          data: [],
        },
        {
          name: 'Inpatient',
          data: [],
        },
        {
          name: 'Lab',
          data: [],
        },
      ];
      let topCodes = [];

      if (selectedFavoriteProvider) {
        const topServiceLinesDataForSelected =
          dashboard[
            'analytics-provider-topservicelines-' +
              selectedFavoriteProvider.provider_id
          ];
        if (StoreUtil.isLoaded(topServiceLinesDataForSelected)) {
          const groupByServiceLine = groupBy(
            ({ name }) => name,
            topServiceLinesDataForSelected.data,
          );
          newCategories = Object.keys(groupByServiceLine).map(
            (serviceLineName) => {
              return serviceLineName.split(' ');
            },
          );

          for (const serviceLineName of Object.keys(groupByServiceLine)) {
            for (const [
              seriesLocationTypeIndex,
              seriesLocationType,
            ] of newSeries.entries()) {
              let idx = groupByServiceLine[serviceLineName].findIndex(
                (serviceLineRow) =>
                  seriesLocationType.name ===
                  LocationTypeUtil.getName(serviceLineRow.location_type),
              );
              if (idx > -1) {
                newSeries[seriesLocationTypeIndex].data.push({
                  x: serviceLineName,
                  y: groupByServiceLine[serviceLineName][idx].total_patients,
                });
              } else {
                newSeries[seriesLocationTypeIndex].data.push({
                  x: serviceLineName,
                  y: 0,
                  lessThan11: true,
                });
              }
            }
          }
        }

        const topCodesForSelected =
          this.props.dashboard[
            'analytics-provider-top' +
              this.state.selectedTopCodesType +
              '-' +
              selectedFavoriteProvider.provider_id
          ];
        if (StoreUtil.isLoaded(topCodesForSelected)) {
          topCodes = topCodesForSelected.data.sort(
            (topCodeA, topCodeB) => topCodeB.claim_count - topCodeA.claim_count,
          );
        }
      }
      this.setState({ topCodes, series: newSeries, categories: newCategories });
    }
    if (this.props.collection !== prevProps.collection) {
      this.fetchData();
      if (this.state.selectedProvider !== 0)
        this.setState({ selectedProvider: 0 });
    }
  }

  render() {
    const { classes, dashboard, favoriteProviders, isMobile, isEarlyMobile } =
      this.props;

    if (
      !dashboard ||
      !favoriteProviders ||
      !StoreUtil.isLoaded(favoriteProviders)
    ) {
      return <LoadingView />;
    }
    const hasFavorites = favoriteProviders?.data.length > 0;

    const renderItem = ({ item: favorite, index, renderDetail }) => {
      return (
        <Stack key={favorite.provider_id} className={classes.rowRoot}>
          <Box
            sx={{
              border: '1px solid #ebebeb',
              height: 40,
              borderRadius: '5px',
              borderTopRightRadius:
                this.state.selectedProvider === index ? '0' : '5px',
              borderBottomRightRadius:
                this.state.selectedProvider === index ? '0' : '5px',
              background:
                this.state.selectedProvider !== index ? '#FFF' : '#EBEBEB',
              '&:hover': {
                background: '#F5F5F5',
              },
              scrollMarginTop: '80px',
            }}
            ref={
              index === this.state.selectedProvider ? this.scrollRef : undefined
            }
            onClick={() => this.selectProvider(favorite.provider_id, index)}
          >
            <Grid
              container
              alignItems='center'
              sx={{
                height: '100%',
                flexWrap: 'nowrap',
              }}
            >
              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  paddingRight: '8px',
                  marginLeft: '4px',
                  flexGrow: 1,
                  flexShrink: 1,
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    paddingLeft: 1,
                  }}
                  style={{
                    overflow: 'hidden',
                    textWrap: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {favorite.is_affiliated ? (
                    <Typography component='span'>
                      <Icon className={classes.affiliatedIcon}>hub</Icon>
                    </Typography>
                  ) : (
                    <Box
                      component='span'
                      sx={{
                        width: '20px',
                        height: '14px',
                        display: 'inline-block',
                      }}
                    ></Box>
                  )}
                  <Typography component='span'>
                    <Icon className={classes.favoriteIcon}>
                      {this.props.collection === 'favorite' ||
                      favorite.is_favorite
                        ? 'favorite'
                        : 'favorite_border'}
                    </Icon>
                  </Typography>

                  <Typography
                    fontSize='12px'
                    component='span'
                    noWrap
                    className={classes.providerName}
                  >
                    {StringUtil.toTitleCase(favorite.last_name)}, &nbsp;
                    {StringUtil.toTitleCase(favorite.first_name)}
                    <Typography
                      component='span'
                      className={classes.credential}
                      fontWeight='300'
                      noWrap
                    >
                      {favorite.credential ? ', ' + favorite.credential : ''}
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  justifyContent: isMobile ? 'flex-end' : 'flex-start',
                  paddingRight: '8px',
                  gap: '8px',
                  flexGrow: 0,
                  flexShrink: 0,
                  maxWidth: '45%',
                  minWidth: '45%',
                  flexBasis: isMobile ? '35%' : '45%',
                  flexDirection: 'row-reverse',
                }}
              >
                <div className={classes.locationContainer}>
                  <Typography
                    color='#333333'
                    fontWeight='300'
                    fontSize={isMobile ? '10px' : '12px'}
                    noWrap
                  >
                    {favorite.location_city &&
                      favorite.location_state &&
                      `${StringUtil.toTitleCase(favorite.location_city)}, ${
                        favorite.location_state
                      }`}
                  </Typography>
                </div>
                {!isMobile && (
                  <div className={classes.specialtyContainer}>
                    <Typography className={classes.providerSpecialty}>
                      {favorite.specialty}
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          </Box>
          {(typeof this.state.selectedProvider === undefined ||
            this.state.selectedProvider === index) &&
            (isMobile || isEarlyMobile) &&
            renderDetail?.()}
        </Stack>
      );
    };

    return (
      <>
        <Grid
          container
          sx={{
            px: 3,
            py: 2,
          }}
        >
          {!hasFavorites && (
            <Grid
              item
              style={{
                flexGrow: 1,
                maxWidth: 'unset',
                backgroundColor: hasFavorites ? '' : '#ebebeb',
              }}
              xs={isMobile || isEarlyMobile ? 12 : 6}
            >
              <div className={classes.noFavorites}>
                <p>
                  Click the heart icon on Provider, Location, and Contact pages
                  to add them to your favorites.
                </p>
                <span className={classes.favoriteHeart}>
                  <Icon>favorite</Icon>
                </span>
                <Link to={'/providers'}>See all providers</Link>
              </div>
            </Grid>
          )}
          {hasFavorites && (
            <Grid item xs={isMobile || isEarlyMobile ? 12 : 6}>
              {StoreUtil.getData(favoriteProviders).map((item, index) => {
                return renderItem({
                  item,
                  index,
                  renderDetail: this.renderSelectedProviderDetails,
                });
              })}
              {!isMobile && !isEarlyMobile && Array.from({ length: 10 - favoriteProviders.data.length + 1 }).map(
                (_, index) => {
                  return (
                    <Stack key={`blank-${index}`} className={classes.rowRootEmpty}>
                      <Box
                        sx={{
                          height: 42,
                          borderRadius: '5px',
                          borderTopRightRadius: '5px',
                          borderBottomRightRadius: '5px',
                          scrollMarginTop: '80px',
                        }}
                      />
                    </Stack>
                  );
                },
              )}
            </Grid>
          )}
          {!isMobile &&
            !isEarlyMobile &&
            this.renderSelectedProviderDetails(this.state.selectedProvider)}
        </Grid>
        <Box
          sx={{
            paddingLeft: 1,
            fontSize: '12px',
          }}
        >
          {favoriteProviders?.data.length > 0 &&
            (this.props.collection === 'favorite' ? (
              <Link style={{ paddingLeft: '24px' }} to={'/favorite_providers'}>
                See all favorite providers
              </Link>
            ) : (
              <Link
                style={{ paddingLeft: '24px' }}
                to={`/${this.props.collection}_providers`}
              >
                See all {this.props.collection} providers
              </Link>
            ))}
        </Box>

        {this.state.newNoteDialogOpen && (
          <ProviderCommentsDialog
            clientId={this.props.app.selectedClient}
            open={this.state.newNoteDialogOpen}
            provider={favoriteProviders?.data?.[this.state.selectedProvider]}
            onSave={this.onSaveNewNote}
            onClose={this.closeNewNoteDialog}
          />
        )}
      </>
    );
  }

  renderSelectedProviderDetails = () => {
    const { classes, favoriteProviders } = this.props;
    const { series, categories } = this.state;
    const isLoaded = StoreUtil.isLoaded(favoriteProviders);
    const noFavorites = isLoaded && favoriteProviders.data.length === 0;
    const options = {
      legend: {
        show: false,
      },
      chart: {
        height: 350,
        type: 'treemap',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
        },
      },
      grid: {
        padding: {
          left: 20,
        },
      },
    };
    const favorite = favoriteProviders.data[this.state.selectedProvider];
    const { isMobile } = this.props;
    return (
      <DetailContainer>
        <Grid item xs={12}>
          <Box
            sx={{
              paddingRight: '8px',
              textAlign: 'right',
            }}
            display='flex'
            justifyContent={isMobile ? 'flex-start' : 'flex-end'}
            gap='15px'
          >
            {favorite && (
              <>
                <ListItemLink
                  component={Link}
                  to={'/providers/' + favorite?.provider_id}
                >
                  Go to {favorite?.last_name}, &nbsp;{favorite?.first_name}
                </ListItemLink>
                <ListItemActionButton
                  style={{
                    lineHeight: '16px',
                    fontSize: '10px',
                  }}
                  onClick={() => this.openNewNoteDialog(favorite?.provider_id)}
                >
                  + New Note
                </ListItemActionButton>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <AnalyticsCard>
            <>
              <Typography fontSize='18px' color='#333333' textAlign='center'>
                Top 3&nbsp;
                {this.props.isMobile && <br />}
                <Typography
                  component='span'
                  sx={{
                    fontSize: '18px',
                    textDecoration:
                      this.state.selectedTopCodesType === 'dx'
                        ? 'underline'
                        : 'none',
                    color:
                      this.state.selectedTopCodesType === 'dx'
                        ? '#333333'
                        : '#7E7E7E',
                    cursor: 'pointer',
                    margin: '0 0 0 10px',
                  }}
                  onClick={this.clickTopDx}
                >
                  DX Codes
                </Typography>
                <Typography
                  component='span'
                  sx={{
                    fontSize: '18px',
                    textDecoration:
                      this.state.selectedTopCodesType === 'px'
                        ? 'underline'
                        : 'none',
                    color:
                      this.state.selectedTopCodesType === 'px'
                        ? '#333333'
                        : '#7E7E7E',
                    cursor: 'pointer',
                    margin: '0 0 0 10px',
                  }}
                  onClick={this.clickTopPx}
                >
                  PX Codes
                </Typography>
              </Typography>
              <Grid container justifyContent='center' alignItems='center'>
                {noFavorites && (
                  <span className={classes.noFavoritesMessage}>
                    No favorite providers
                  </span>
                )}
                <Chart
                  type='treemap'
                  height={290}
                  options={{
                    ...options,
                    tooltip: {
                      custom: ({ dataPointIndex }) => {
                        const topCode = this.state.topCodes[dataPointIndex];
                        return `<div style="padding:8px;width:15rem;">
            <div ><b>${topCode?.name ?? ''}</b></div>
            <div style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;white-space:break-spaces;">${topCode?.description ?? ''}</div>
            <div>Claims Count: ${topCode?.claim_count ?? ''}<div>
          </div>`;
                      },
                    },
                  }}
                  series={[
                    {
                      data: this.state.topCodes.map((topCode) => ({
                        x: topCode.code,
                        y: topCode.claim_count,
                      })),
                    },
                  ]}
                />
              </Grid>
            </>
          </AnalyticsCard>
        </Grid>
        <Grid item xs={6}>
          <AnalyticsCard>
            <>
              <Typography fontSize='18px' color='#333333' textAlign='center'>
                Top 3 Service Lines
              </Typography>
              <Grid container justifyContent='center' alignItems='center'>
                {noFavorites && (
                  <span className={classes.noFavoritesMessage}>
                    No favorite providers
                  </span>
                )}
                {categories.length > 0 && (
                  <Chart
                    type='bar'
                    height='300px'
                    options={{
                      chart: {
                        stacked: true,
                        toolbar: {
                          show: false,
                        },
                        animations: {
                          enabled: false,
                        },
                      },
                      xaxis: {
                        categories: categories,
                        labels: {
                          rotate: -45,
                        },
                      },
                      tooltip: {
                        enabled: true,
                        shared: true,
                        intersect: false,
                        followCursor: true,
                        x: {
                          formatter: (x) => {
                            return `${x.join(' ')}`;
                          },
                        },
                        y: {
                          formatter: (val, opts) => {
                            let showLessThan11 =
                              series[opts.seriesIndex].data[opts.dataPointIndex]
                                .lessThan11;
                            if (showLessThan11) {
                              return '< 11';
                            }
                            return StringUtil.numberForChart(val);
                          },
                        },
                      },
                      yaxis: {
                        show: false,
                        axisTicks: {
                          show: false,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      grid: {
                        show: false,
                      },
                      legend: {
                        show: false,
                      },
                      colors: ColorUtil.getColors(7),
                    }}
                    series={series}
                  />
                )}
              </Grid>
            </>
          </AnalyticsCard>
        </Grid>
      </DetailContainer>
    );
  };

  fetchData() {
    const { favoriteProviders } = this.props;

    if (
      StoreUtil.needsLoadNoCache(favoriteProviders) &&
      !StoreUtil.isLoading(favoriteProviders)
    ) {
      let action = loadFavoriteProviders({
        clientId: this.props.clientId,
      });
      if (this.props.collection !== 'favorite') {
        action = loadRecentProviders({
          clientId: this.props.clientId,
        });
      }
      this.props.dispatch(action);
      if (this.state.selectedContact !== 0)
        this.setState({ selectedContact: 0 });
    }
  }

  fetchSelected() {
    const { dispatch, favoriteProviders } = this.props;
    let selectedFavoriteProvider =
      favoriteProviders.data[this.state.selectedProvider];
    if (selectedFavoriteProvider) {
      dispatch(
        loadProviderTopServiceLines({
          providerId: selectedFavoriteProvider.provider_id,
        }),
      );

      dispatch(
        loadProviderTopDx({ providerId: selectedFavoriteProvider.provider_id }),
      );
      dispatch(
        loadProviderTopPx({ providerId: selectedFavoriteProvider.provider_id }),
      );
    }
  }

  selectProvider = (providerId, index) => {
    this.setState(
      {
        selectedProvider: index,
      },
      () => {
        this.fetchSelected();
      },
    );
    this.props.isMobile &&
      setTimeout(() => {
        this.scrollRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 100);
  };

  clickTopDx = () => {
    this.setState({
      selectedTopCodesType: 'dx',
    });
  };

  clickTopPx = () => {
    this.setState({
      selectedTopCodesType: 'px',
    });
  };

  onSaveNewNote = (data) => {
    const { app, user, dispatch } = this.props;
    if (
      data.body &&
      data.body.length > 0 &&
      (data.providers.length > 0 ||
        data.contacts.length > 0 ||
        data.location_id)
    ) {
      data.client_id = app.selectedClient;
      data.user_id = user.id;
      data.providers = data.providers.filter((p) => p !== undefined);
      data.providers.push(
        this.props.favoriteProviders.data[this.state.selectedProvider]
          .provider_id,
      );

      dispatch(upsertProviderComment(data));
    }
    this.closeNewNoteDialog();
  };

  closeNewNoteDialog = () => {
    this.setState({
      newNoteDialogOpen: false,
    });
  };

  openNewNoteDialog = () => {
    if (this.props.user.is_admin) {
      this.props.dispatch(
        showConfirmationDialog({
          title: 'Admin Users Can Not Create Notes',
          content: 'Login as a non-admin user to create activity notes.',
          confirmLabel: 'OK',
          onConfirm: () => {
            // do nothing
          },
        }),
      );
    } else {
      this.setState({
        newNoteDialogOpen: true,
      });
    }
  };
}

DashboardProvidersPage.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  collection: PropTypes.oneOf(['favorite', 'recent']),
  isEarlyMobile: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  router: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  dashboard: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  userId: PropTypes.number,
  favoriteProviders: PropTypes.object,
};

function mapStateToProps(state, props) {
  const { app, user, dashboard } = state;
  const userId = state.user ? state.user.id : null;
  const clientId = state.app.selectedClient;

  return {
    app,
    user,
    userId,
    clientId,
    dashboard,
    favoriteProviders: StoreUtil.get(
      dashboard,
      `${props.collection}-providers-${clientId}`,
    ),
  };
}

const withStyled = withStyles(styles)(withIsMobile(DashboardProvidersPage));
const connected = connect(mapStateToProps)(withStyled);
const routed = withRouter(connected);
export { routed as DashboardProvidersPage };
