/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import fileDownload from 'js-file-download';
import {
  Typography,
  Card,
  CardContent,
  Chip,
  Icon,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  FormControlLabel,
  FormControl,
  TextField,
  Checkbox,
} from '@mui/material';
import Check from '@mui/icons-material/Check';

import { subDays, startOfDay, endOfDay } from 'date-fns';
import DatePicker from '@mui/lab/DatePicker';

import withStyles from '@mui/styles/withStyles';
import { GetApp } from '@mui/icons-material';

import { LocationsUtil, withRouter } from 'utils';
import {
  DynamicTable,
  FindProviderDialog,
  FindUserDialog,
  SmallIconTextButton,
  LoadingView,
} from 'components';
import { FindContactDialog } from 'contacts';
import { FindLocationDialog } from 'locations';
import { queryProviderComments } from 'store/actions/providerCommentsActions';
import {
  API_TAG_NAMESPACES_LIST_BY_CLIENT,
  listTagNamespacesByClient,
} from 'store/actions/tagNamespacesActions';

import axiosInstance from 'utils/axiosUtil';
import {
  CsvUtil,
  DataUtil,
  StoreUtil,
  DateUtil,
  EncounterTypeUtil,
} from 'doctivity-shared/utils';

const styles = (theme) => ({
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  filters: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  lineBreaks: {
    whiteSpace: 'pre',
  },
  paddedRow: {
    paddingTop: '5px',
    paddingBottom: '5px',
    whiteSpace: 'pre',
  },
  encounterIcon: {
    fontSize: 16,
    color: '#333333',
    marginLeft: 2,
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    fontSize: 24,
    paddingRight: 30,
  },
  headerContainer: {
    marginBottom: theme.spacing(3),
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacer: {
    flex: 1,
  },
  pdfButton: {
    flex: 1,
  },
  pdfIcon: {
    fontSize: 20,
    fontWeight: 200,
  },
  filterSpace: {
    width: 4,
  },
  rangeControl: {
    minWidth: 90,
  },
  tabs: {
    paddingLeft: theme.spacing(3),
  },
  card: {
    marginBottom: theme.spacing(3),
  },
  filterColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
});

// this controls which columns are displayed and how they are looked up in data
const columns = [
 
  {
    label: '',
    key: 'encounter_type_id',
    format: (id, row, rowClasses) => (
      <Icon className={rowClasses.encounterIcon}>
        {EncounterTypeUtil.getIcon(id)}
      </Icon>
    ),
    style: { width: 20 },
    hideForMobile: false,
  },
  {
    label: 'Date',
    key: 'comment_date',
    type: 'date',
    format: DateUtil.formatDateForUser,
    style: { width: 60 },
    hideForMobile: false,
  },
  {
    label: 'Location',
    key: 'location.address1',
    format: (_id, { location }) =>
      location && LocationsUtil.formatFullNameAndAddress(location, false),
    style: { width: 200 },
    hideForMobile: true,
  },
  {
    label: 'Providers',
    key: 'providers',
    format: (providers, row, rowClasses) => {
      const names = [];
      if (providers && providers.length > 0) {
        let counter = 0
        providers.every((p) => {
          counter++;
          if (counter > 5) {
            names.push(`(+${providers.length-5} more)`);
            return false;
          } else {
            names.push(`${p.last_name}, ${p.first_name}`);
            return true;
          }
          
        });
      }
      return <div className={rowClasses.paddedRow}>{names.join('\n')}</div>;
    },
    // fulltext: true,
    // filterFields: ['providers.last_name', 'providers.first_name'],
    sortable: false,
    style: { width: 180 },
    hideForMobile: true,
  },
  {
    label: 'Contacts',
    key: 'contacts',
    format: (contacts, row, rowClasses) => {
      const names = [];
      if (row.contacts && row.contacts.length > 0) {
        let counter = 0
        contacts.every((c) => {
          counter++;
          if (counter > 5) {
            names.push(`(+${contacts.length-5} more)`);
            return false;
          } else {
            names.push(`${c.last_name}, ${c.first_name}`);
            return true;
          }
        });
      }
      return <div className={rowClasses.lineBreaks}>{names.join('\n')}</div>;
    },
    // fulltext: true,
    // filterFields: ['contacts.last_name', 'contacts.first_name'],
    sortable: false,
    style: { width: 180 },
    hideForMobile: true,
  },
  {
    label: 'User',
    key: 'user',
    format: (user) => user && `${user.last_name}, ${user.first_name}`,
    style: { width: 100 },
    hideForMobile: false,
  },
  {
    label: 'Note',
    key: 'body',
    format: (body) => body && body.replace(/(.{225}).*/, '$1 ...').substring(0,228),
    style: { maxWidth: 200, width: 200, whiteSpace: 'nowrap', wordWrap: 'nowrap'},
    hideForMobile: true,
  },
];
const isValidDate = (d) => d instanceof Date && !isNaN(d);
const FILTER_KEYS = [
  'tags',
  'documents',
  'users',
  'contacts',
  'providers',
  'locations',
  'encounterTypes',
  'needsResolved',
];

class ProviderCommentsPage extends React.Component {
  constructor(props) {
    super(props);
    const startDate = new Date(
      sessionStorage.getItem('startDate') ?? undefined,
    );
    const endDate = new Date(sessionStorage.getItem('endDate') ?? undefined);
    let filters = JSON.parse(
      sessionStorage.getItem('activity-note-filters') ??
        '{"tags": [],"users":[],"contacts":[],"providers":[],"locations":[],"encounterTypes":[]}',
    );
    let filterClient = parseInt(
      sessionStorage.getItem('activity-note-client') ?? '-1',
      10,
    );

    if (filterClient && filterClient !== props.app.selectedClient) {
      sessionStorage.removeItem('activity-note-filters');
      sessionStorage.removeItem('activity-note-client');
      filters = {};
    }
    this.state = {
      locations: filters.locations ?? [],
      providers: filters.providers ?? [],
      users: filters?.hasChanged ? filters.users : [props.user],
      contacts: filters.contacts ?? [],
      tags: filters.tags ?? [],
      documents: filters.documents ?? [],
      encounterTypes: filters.encounterTypes ?? [],
      needsResolved: filters.needsResolved ?? false,
      findLocationOpen: false,
      findProviderOpen: false,
      findContactOpen: false,
      findUserOpen: false,
      tagsOpen: false,
      tagsAnchorEl: null,
      tagsNamespace: null,
      findEncounterTypeOpen: false,
      encounterTypeAnchorEl: null,
      startDate: startOfDay(
        isValidDate(startDate) ? startDate : subDays(new Date(), 30),
      ),
      endDate: endOfDay(isValidDate(endDate) ? endDate : new Date()),
      query: {
        distinct: true, // this keeps counts accurate with multiple rows
        where: {},
        include: [
          {
            association: 'user',
            attributes: ['id', 'first_name', 'last_name'],
          },
          {
            association: 'providers',
            attributes: ['id', 'first_name', 'last_name', 'middle_name'],
          },
          {
            association: 'contacts',
            attributes: ['id', 'first_name', 'last_name'],
          },
          {
            association: 'tags',
            attributes: ['id', 'name', 'namespace_id'],
          },
          {
            association: 'location',
            attributes: ['id', 'name', 'address1', 'address2', 'city', 'state'],
          },
        ],
        // order set in buildQuery
        limit: 25,
        offset: 0,
      },
    };

    columns[0].onFilterClick = () => {
      this.onAdd();
    };
  }

  componentDidMount() {
    this.fetchData();
    sessionStorage.setItem(
      'activity-note-client',
      this.props.app.selectedClient,
    );
    if (
      StoreUtil.needsLoadLongCache(this.props.tagNamespaces) ||
      this.props.tagNamespaces.clientId !== this.props.app.selectedClient
    ) {
      this.fetchTagNamespaces();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // anytime the client changes
    if (this.props.app.selectedClient !== prevProps.app.selectedClient) {
      sessionStorage.removeItem('activity-note-filters');
      sessionStorage.removeItem('activity-note-client');
      this.fetchData();
    } else if (this.props.editComment !== prevProps.editComment) {
      // is the list data older than the edited data?
      if (
        StoreUtil.hasSavedSinceLoad(this.props.editComment, this.props.comments)
      ) {
        this.fetchData();
      }
    } else if (StoreUtil.needsLoadNoCache(this.props.comments)) {
      this.fetchData();
    }

    if (
      StoreUtil.needsLoadLongCache(this.props.tagNamespaces) ||
      this.props.tagNamespaces.clientId !== this.props.app.selectedClient
    ) {
      this.fetchTagNamespaces();
    }
    if (this.state !== prevState) {
      for (const key of FILTER_KEYS) {
        if (this.state[key] !== prevState[key]) {
          const filters = FILTER_KEYS.reduce((acc, k) => {
            acc[k] = this.state[k];
            return acc;
          }, {});
          sessionStorage.setItem(
            'activity-note-filters',
            JSON.stringify({ hasChanged: true, ...filters }),
          );
          break;
        }
      }
    }
  }

  buildQuery() {
    const { app } = this.props;

    const {
      query,
      locations,
      providers,
      contacts,
      encounterTypes, 
      needsResolved,
    } = this.state;

    const users = this.getUsers();

    // restrict to current selected client
    query.where.client_id = app.selectedClient;
    const location = query.include.find((i) => i.association === 'location');
    if (location && locations && locations.length > 0) {
      location.where = { id: locations.map((l) => l.id) };
    } else if (location?.where) {
      delete location.where;
    }
    if (providers && providers.length > 0) {
      query.include[1].where = {
        id: providers.map((p) => p.id),
      };
    } else if (query.include[1].where) {
      delete query.include[1].where;
    }
    if (contacts && contacts.length > 0) {
      query.include[2].where = {
        id: contacts.map((c) => c.id),
      };
    } else if (query.include[2].where) {
      delete query.include[2].where;
    }
    const tagInclude = query.include.find((i) => i.association === 'tags');
    if (this.state.tags && this.state.tags.length > 0) {
      tagInclude.where = { $or: [] };
      this.state.tags.forEach((namespace) => {
        tagInclude.where.$or.push({
          namespace_id: namespace.id,
          id: namespace.tags.map((t) => t.id),
        });
      });
    } else if (tagInclude.where) {
      delete tagInclude.where;
    }

    // Documents
    
    const documentInclude = query.include.find((i) => i.association === 'documents');
    if (this.state.documents && this.state.documents.length > 0) {
      documentInclude.where = { $or: [] };
      //this.state.documents.forEach((namespace) => {
        //tagInclude.where.$or.push({
          //namespace_id: namespace.id,
          //id: namespace.tags.map((t) => t.id),
       // });
     // });
    } else if (tagInclude.where) {
      delete documentInclude.where;
    }
  
    if (encounterTypes && encounterTypes.length > 0) {
      query.where.encounter_type_id = encounterTypes.map((et) => et.id);
    } else if (query.where.encounter_type_id) {
      delete query.where.encounter_type_id;
    }
    if (users && users.length > 0) {
      query.include[0].where = {
        id: users.map((u) => u.id),
      };
    } else if (query.include[0].where) {
      delete query.include[0].where;
    }
    if (needsResolved) {
      query.where.needs_followup = true;
      query.where.$or = [{ is_resolved: false }, { is_resolved: null }];
      if (!query.order) {
        query.order = [['due_date', 'DESC']];
      } else if (query.order[0][0] === 'comment_date') {
        query.order[0][0] = 'due_date';
      }
    } else {
      delete query.where.needs_followup;
      delete query.where.$or;
      if (!query.order) {
        query.order = [['comment_date', 'DESC']];
      } else if (query.order[0][0] === 'due_date') {
        query.order[0][0] = 'comment_date';
      }
    }
    query.where.comment_date = {
      $gte: DateUtil.formatDateTimeFromDB(this.state.startDate),
      $lte: DateUtil.formatDateTimeFromDB(this.state.endDate),
    };
    return query;
  }

  fetchDataWithPagingReset() {
    this.setState(
      { query: { ...this.state.query, offset: 0 } },
      this.fetchData,
    );
  }

  fetchData() {
    const { dispatch } = this.props;
    // console.log('QUERY: ', query);
    dispatch(queryProviderComments(this.buildQuery()));
  }

  fetchTagNamespaces() {
    this.props.dispatch(
      listTagNamespacesByClient(this.props.app.selectedClient),
    );
  }

  fetchCSVDownload() {
    axiosInstance
      .get('/ProviderComments', {
        params: {
          opts: {
            ...this.buildQuery(),
            format: 'csv',
          },
        },
      })
      .then((response) => {
        const filename = 'doctivity_activity_notes.csv';
        try {
          const data = this.formatCSV(response.data);
          fileDownload(data, filename);
        } catch (err) {
          console.error(`Could not format csv for ${filename}`);
          console.error(err);
          fileDownload(response.data, filename);
        }
      });
  }

  formatCSV(data) {
    let csvColumns = CsvUtil.stringToMatrix(data);
    if (csvColumns.length > 0) {
      let encounterTypeIndex = -1;
      let needsFollowupIndex = -1;
      let isResolvedIndex = -1;
      let locationIndex = -1;
      for (let index = 0; index < csvColumns[0].length; index++) {
        if (csvColumns[0][index] === 'encounter_type_id') {
          encounterTypeIndex = index;
          csvColumns[0][index] = 'Encounter Type';
        } else if (csvColumns[0][index] === 'body') {
          csvColumns[0][index] = 'Note';
        } else if (csvColumns[0][index] === 'needs_followup') {
          needsFollowupIndex = index;
        } else if (csvColumns[0][index] === 'is_resolved') {
          isResolvedIndex = index;
        } else if (csvColumns[0][index] === 'location') {
          locationIndex = index;
        }
      }

      // Loop through again to clean up all '*id' columns,
      // once we have changed the name of the id columns we want to keep
      const idColumnIndecesToRemove = [];
      for (let index = 0; index < csvColumns[0].length; index++) {
        if (
          csvColumns[0][index].endsWith('_id') ||
          csvColumns[0][index].endsWith('.id')
        ) {
          idColumnIndecesToRemove.push(index);
        }
      }

      // For now just don't put data into empty rows, we'll see if we can fix the empty rows on backend side first
      csvColumns = csvColumns.filter(
        (row) =>
          row !== '' && (row.length > 1 || (row.length === 1 && row[0] !== '')),
      );

      for (let index = 1; index < csvColumns.length; index++) {
        if (encounterTypeIndex > -1) {
          const encounterType = parseInt(
            csvColumns[index][encounterTypeIndex],
            10,
          );
          if (encounterType) {
            const encounterTypeName =
              EncounterTypeUtil.getType(encounterType).name;
            csvColumns[index][encounterTypeIndex] = encounterTypeName;
          }
        }
        if (needsFollowupIndex > -1) {
          csvColumns[index][needsFollowupIndex] =
            csvColumns[index][encounterTypeIndex] === '1' ? 'Yes' : 'No';
        }
        if (isResolvedIndex > -1) {
          csvColumns[index][isResolvedIndex] =
            csvColumns[index][isResolvedIndex] === '1' ? 'Yes' : 'No';
        }
      }

      if (locationIndex > -1) {
        idColumnIndecesToRemove.push(locationIndex);
      }

      for (let index = 0; index < idColumnIndecesToRemove.length; index++) {
        // We know that the index list was built sequentially, so we can adjust for the offset post-removal
        // sequentially as well
        csvColumns = DataUtil.removeMatrixColumn(
          csvColumns,
          idColumnIndecesToRemove[index] - index,
        );
      }
    }
    return CsvUtil.matrixToString(csvColumns);
  }

  render() {
    const { comments, classes, app } = this.props;

    const {
      query,
      findProviderOpen,
      findContactOpen,
      findUserOpen,
      findEncounterTypeOpen,
      providers,
      locations,
      findLocationOpen,
      contacts,
      encounterTypes,
    } = this.state;

    const users = this.getUsers();

    if (StoreUtil.isSaving(this.props.editComment)) {
      return <LoadingView />;
    }

    return (
      <>
        <div className={classes.container}>
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.tableHeader}>
                <Typography component='h2' variant='h6' color='primary'>
                  All Activity Notes
                </Typography>
                <div className={classes.pdfButton}>
                  <IconButton
                    onClick={() => {
                      this.fetchCSVDownload();
                    }}
                    size='large'
                  >
                    <GetApp className={classes.pdfIcon} />
                  </IconButton>
                </div>
              </div>

              <div className={classes.filters}>
                <FormControl className={classes.rangeControl}>
                  <DatePicker
                    renderInput={(params) => <TextField {...params} />}
                    label='From'
                    name='from'
                    value={this.state.startDate}
                    views={['month', 'day']}
                    maxDate={this.state.endDate}
                    onChange={this.onChangeStartDate}
                    inputFormat={DateUtil.LDML_DISPLAY_FORMAT}
                  />
                </FormControl>
                <div className={classes.filterSpace} />
                <FormControl className={classes.rangeControl}>
                  <DatePicker
                    renderInput={(params) => <TextField {...params} />}
                    label='To'
                    value={this.state.endDate}
                    views={['month', 'day']}
                    minDate={this.state.startDate}
                    maxDate={new Date()}
                    onChange={this.onChangeEndDate}
                    name='to'
                    inputFormat={DateUtil.LDML_DISPLAY_FORMAT}
                  />
                </FormControl>
                <div className={classes.filterSpace} />
                <div className={classes.filterSpace} />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.needsResolved}
                      onChange={this.onNeedsResolvedChange}
                    />
                  }
                  label='Needs Resolved'
                />
              </div>
              <div className={classes.filters}>
                {locations.map((p) => (
                  <Chip
                    key={`locations-${p.id}`}
                    label={`${p.name ?? ''} ${p.address1}`}
                    onDelete={() => {
                      this.onDeleteLocation(p);
                    }}
                    variant='outlined'
                  />
                ))}
                {!locations.length && (
                  <SmallIconTextButton
                    key='by-location'
                    onClick={this.onAddLocation}
                    icon='filter_list'
                    text='By Location'
                  />
                )}
                {providers.map((p) => (
                  <Chip
                    key={`prpvider-${p.id}`}
                    label={`${p.last_name}, ${p.first_name}`}
                    onDelete={() => {
                      this.onDeleteProvider(p);
                    }}
                    variant='outlined'
                  />
                ))}
                {!providers.length && (
                  <SmallIconTextButton
                    key='by-provider'
                    onClick={this.onAddProvider}
                    icon='filter_list'
                    text='By Provider'
                  />
                )}
                <div className={classes.filterSpace} />
                {contacts.map((c) => (
                  <Chip
                    key={`contact-${c.id}`}
                    label={`${c.last_name}, ${c.first_name}`}
                    onDelete={() => {
                      this.onDeleteContact(c);
                    }}
                    variant='outlined'
                  />
                ))}
                {!contacts.length && (
                  <SmallIconTextButton
                    key='by-contact'
                    onClick={this.onAddContact}
                    icon='filter_list'
                    text='By Contacts'
                  />
                )}
                <div className={classes.filterSpace} />
                <SmallIconTextButton
                  key='by-encounter-type'
                  onClick={this.onAddEncounterType}
                  icon='filter_list'
                  text='By Encounter Type'
                />
                {encounterTypes?.length > 0 && <Chip
                  label={encounterTypes.map((s) => s.name).join(', ')}
                  onDelete={() => {
                    this.onDeleteEncounterType('all');
                  }}
                  variant='outlined'
                />}
                <div className={classes.filterSpace} />
                {users.map((u) => (
                  <Chip
                    key={`user-${u.id}`}
                    label={`${u.last_name}, ${u.first_name}`}
                    onDelete={() => {
                      this.onDeleteUser(u);
                    }}
                    variant='outlined'
                  />
                ))}
                {!users.length && (
                  <SmallIconTextButton
                    key='by-user'
                    onClick={this.onAddUser}
                    icon='filter_list'
                    text='By User'
                  />
                )}
                <div className={classes.filterSpace} />
                {this.state.tags.map((namespace) => (
                  <Chip
                    key={`tag-${namespace.id}`}
                    label={`${namespace.name}: ${namespace.tags.map((t) => t.name).join(', ')}`}
                    onDelete={() => {
                      this.onDeleteTag(namespace);
                    }}
                    variant='outlined'
                  />
                ))}
                {!StoreUtil.isLoaded(this.props.tagNamespaces) ? (
                  <LoadingView />
                ) : (
                  StoreUtil.getData(this.props.tagNamespaces).map(
                    (namespace) => (
                      <SmallIconTextButton
                        key={`by-namespace-${namespace.id}`}
                        onClick={(event) => {
                          this.onAddTagNamespace(event, namespace);
                        }}
                        icon='filter_list'
                        text={`By ${namespace.name}`}
                      />
                    ),
                  )
                )}
              </div>

              <DynamicTable
                columns={columns} // providers.length > 0 ? columns.slice(1) :
                data={comments}
                showFilters={false}
                ContainerType={React.Fragment}
                query={query}
                onQueryChange={this.onQueryChange}
                getRowHref={this.getRowHref}
                onRowClick={this.onRowClick}
                rowClasses={classes}
              />
              {findLocationOpen && (
                <FindLocationDialog
                  clientId={app.selectedClient}
                  open={findLocationOpen}
                  onSelect={this.onLocationSelected}
                  onClose={this.onLocationSelected}
                />
              )}
              {findProviderOpen && (
                <FindProviderDialog
                  clientId={app.selectedClient}
                  open={findProviderOpen}
                  onSelect={this.onProviderSelected}
                />
              )}
              {findContactOpen && (
                <FindContactDialog
                  clientId={app.selectedClient}
                  open={findContactOpen}
                  onSelect={this.onContactSelected}
                />
              )}
              {findUserOpen && (
                <FindUserDialog
                  clientId={app.selectedClient}
                  open={findUserOpen}
                  onSelect={this.onUserSelected}
                />
              )}
              {findEncounterTypeOpen && (
                <Menu
                  anchorEl={this.state.encounterTypeAnchorEl}
                  onClose={() => this.onEncounterTypeSelected(null)}
                  open={findEncounterTypeOpen}
                >
                  {EncounterTypeUtil.getAll().map((s) => (
                    <MenuItem
                      key={s.id}
                      onClick={() => this.onEncounterTypeSelected(s)}
                    >
                      {this.state.encounterTypes.find(
                        (et) => et.id === s.id,
                      ) ? (
                        <ListItemIcon>
                          <Check />
                        </ListItemIcon>
                      ) : null}
                      {s.name}
                    </MenuItem>
                  ))}
                </Menu>
              )}
              {this.state.tagsOpen && this.state.tagsAnchorEl && (
                <Menu
                  anchorEl={this.state.tagsAnchorEl}
                  onClose={() => this.onTagSelected(null)}
                  open={this.state.tagsOpen}
                >
                  {this.state.tagsNamespace.tags.map((t) => (
                    <MenuItem key={t.id} onClick={() => this.onTagSelected(t)}>
                      {t.name}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </CardContent>
          </Card>
        </div>
      </>
    );
  }

  getUsers() {
    if (this.state.users) {
      return this.state.users;
    }
    return [this.props.user];
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData);
  };

  getRowHref = (row) => {
    return `/notes/${row.id}`;
  };
  onRowClick = (row) => {
    this.props.router.navigate(`/notes/${row.id}`);
  };

  onAddProvider = () => {
    this.setState({
      findProviderOpen: true,
    });
  };

  onAddLocation = () => {
    this.setState({
      findLocationOpen: true,
    });
  };

  onLocationSelected = (location) => {
    if (!location) {
      this.setState({
        findLocationOpen: false,
      });
    } else {
      this.setState((state) => {
        let existing = false;
        const newLocations = [...state.locations];
        newLocations.forEach((l) => {
          if (l.id === location.id) {
            existing = true;
          }
        });
        if (!existing) {
          newLocations.push(location);
        }
        return {
          locations: newLocations,
          findLocationOpen: false,
        };
      }, this.fetchDataWithPagingReset);
    }
  };
  onProviderSelected = (provider) => {
    if (!provider) {
      // cancel sends a null provider, just close
      this.setState({
        findProviderOpen: false,
      });
    } else {
      this.setState((state) => {
        let existing = false;
        const newProviders = [...state.providers];
        newProviders.forEach((p) => {
          if (p.id === provider.id) {
            existing = true;
          }
        });
        if (!existing) {
          newProviders.push(provider);
        }
        return {
          providers: newProviders,
          findProviderOpen: false,
        };
      }, this.fetchDataWithPagingReset);
    }
  };

  onDeleteLocation = (location) => {
    this.setState((state) => {
      const newLocations = [...state.locations];
      if (newLocations.length > 0) {
        const i = newLocations.indexOf(location);
        if (i >= 0) {
          newLocations.splice(i, 1);
        }
      }
      return { locations: newLocations };
    }, this.fetchDataWithPagingReset);
  };

  onDeleteProvider = (provider) => {
    this.setState((state) => {
      const newProviders = [...state.providers];
      if (newProviders.length > 0) {
        const i = newProviders.indexOf(provider);
        if (i >= 0) {
          newProviders.splice(i, 1);
        }
      }
      return { providers: newProviders };
    }, this.fetchDataWithPagingReset);
  };

  onAddContact = () => {
    this.setState({
      findContactOpen: true,
    });
  };

  onContactSelected = (contact) => {
    if (!contact) {
      // cancel sends a null provider, just close
      this.setState({
        findContactOpen: false,
      });
    } else {
      this.setState((state) => {
        let existing = false;
        const newContacts = [...state.contacts];
        newContacts.forEach((c) => {
          if (c.id === contact.id) {
            existing = true;
          }
        });
        if (!existing) {
          newContacts.push(contact);
        }
        return {
          contacts: newContacts,
          findContactOpen: false,
        };
      }, this.fetchDataWithPagingReset);
    }
  };

  onDeleteContact = (contact) => {
    this.setState((state) => {
      const newContacts = [...state.contacts];
      if (state.contacts.length > 0) {
        const i = state.contacts.indexOf(contact);
        if (i >= 0) {
          newContacts.splice(i, 1);
        }
      }
      return { contacts: newContacts };
    }, this.fetchDataWithPagingReset);
  };

  onAddUser = () => {
    this.setState({
      findUserOpen: true,
    });
  };

  onUserSelected = (user) => {
    if (!user) {
      // cancel sends a null user, just close
      this.setState({
        findUserOpen: false,
      });
    } else {
      const users = [...this.getUsers()];
      // eslint-disable-next-line no-unused-vars
      this.setState((_state) => {
        let existing = false;
        users.forEach((u) => {
          if (u.id === user.id) {
            existing = true;
          }
        });
        if (!existing) {
          users.push(user);
        }
        return {
          users,
          findUserOpen: false,
        };
      }, this.fetchDataWithPagingReset);
    }
  };

  onDeleteUser = (user) => {
    // eslint-disable-next-line no-unused-vars
    this.setState((_state) => {
      const users = [...this.getUsers()];
      if (users.length > 0) {
        const i = users.indexOf(user);
        if (i >= 0) {
          users.splice(i, 1);
        }
      }
      return { users };
    }, this.fetchDataWithPagingReset);
  };

  onAddTagNamespace = (event, namespace) => {
    this.setState({
      tagsOpen: true,
      tagsAnchorEl: event.target,
      tagsNamespace: namespace,
    });
  };

  onTagSelected = (tag) => {
    if (!tag) {
      this.setState({
        tagsOpen: false,
        tagsAnchorEl: null,
        tagsNamespace: null,
      });
    } else {
      this.setState((state) => {
        console.log(state.tagsNamespace);
        const newTags = [...state.tags];
        let namespace = newTags.find((n) => state.tagsNamespace.id === n.id);
        if (!namespace) {
          namespace = {
            ...state.tagsNamespace,
            tags: [tag],
          };
          newTags.push(namespace);
        } else {
          let existingTag = namespace.tags.find((t) => t.id === tag.id);
          if (!existingTag) {
            if (!namespace.activity_notes_multiple && namespace.tags > 0) {
              namespace.tags = [];
            }
            namespace.tags = [...namespace.tags, tag];
          } // if its already in there, do nothing
        }
        return {
          tags: newTags,
          tagsOpen: false,
          tagsAnchorEl: null,
          tagsNamespace: null,
        };
      }, this.fetchDataWithPagingReset);
    }
  };

  onDeleteTag = (tag) => {
    this.setState((state) => {
      if (state.tags.length > 0) {
        const i = state.tags.indexOf(tag);
        if (i >= 0) {
          state.tags.splice(i, 1);
        }
      }
      return { tags: state.tags };
    }, this.fetchDataWithPagingReset);
  };

  onAddEncounterType = (event) => {
    this.setState({
      findEncounterTypeOpen: true,
      encounterTypeAnchorEl: event.target,
    });
  };

  onEncounterTypeSelected = (encounterType) => {
    if (!encounterType) {
      this.setState({
        encounterTypeAnchorEl: null,
        findEncounterTypeOpen: false,
      });
    } else {
      this.setState((state) => {
        let existing = false;
        state.encounterTypes.forEach((s) => {
          if (s.id === encounterType.id) {
            existing = true;
          }
        });
        const newEncounterTypes = state.encounterTypes.filter(
          (c) => c.id !== encounterType.id,
        );
        if (!existing) {
          newEncounterTypes.push(encounterType);
        }
        return {
          encounterTypes: newEncounterTypes,
          findEncounterTypeOpen: false,
          encounterTypeAnchorEl: null,
        };
      }, this.fetchDataWithPagingReset);
    }
  };

  onDeleteEncounterType = (encounterType) => {
    this.setState((state) => {
      if (encounterType === 'all') {
        return { encounterTypes: [] };
      }
      const newEncounterTypes = [...state.encounterTypes];
      if (state.encounterTypes.length > 0) {
        const i = state.encounterTypes.indexOf(encounterType);
        if (i >= 0) {
          newEncounterTypes.splice(i, 1);
        }
      }
      return { encounterTypes: newEncounterTypes };
    }, this.fetchDataWithPagingReset);
  };

  onNeedsResolvedChange = (event) => {
    if (event.target.checked) {
      columns[1] = {
        label: 'Due Date',
        key: 'due_date',
        type: 'date',
        format: DateUtil.formatDateForUser,
        style: { width: 60 },
        hideForMobile: false,
      };
    } else {
      columns[1] = {
        label: 'Date',
        key: 'comment_date',
        type: 'date',
        format: DateUtil.formatDateForUser,
        style: { width: 60 },
        hideForMobile: false,
      };
    }

    this.setState(
      {
        needsResolved: event.target.checked,
      },
      this.fetchDataWithPagingReset,
    );
  };

  onChangeStartDate = (newDate) => {
    sessionStorage.setItem('startDate', newDate);
    this.setState(
      {
        startDate: startOfDay(newDate),
      },
      this.fetchDataWithPagingReset,
    );
  };

  onChangeEndDate = (newDate) => {
    sessionStorage.setItem('endDate', newDate);
    this.setState(
      {
        endDate: endOfDay(newDate),
      },
      this.fetchDataWithPagingReset,
    );
  };
}

ProviderCommentsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  comments: PropTypes.object.isRequired,
  editComment: PropTypes.object,
  tagNamespaces: PropTypes.object,
};

function mapStateToProps(state) {
  const { app, user, providerComments } = state;

  return {
    app,
    user,
    comments: StoreUtil.get(providerComments, StoreUtil.COMMON_TABLE),
    editComment: StoreUtil.get(providerComments, StoreUtil.COMMON_EDIT_ITEM),
    tagNamespaces: StoreUtil.get(
      state.tagNamespaces,
      API_TAG_NAMESPACES_LIST_BY_CLIENT,
    ),
  };
}

const styled = withStyles(styles)(ProviderCommentsPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentsPage };
