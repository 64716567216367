import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Helmet } from 'react-helmet';
import withStyles from '@mui/styles/withStyles';
import { PatientType, PatientTypes } from 'doctivity-shared/utils';
import { MarketsUtil, withRouter } from 'utils';
import { Typography, Tabs, Tab } from '@mui/material';
import { ClaimsPage } from 'containers/pages';
import { setPatientType } from 'store/actions/appActions';
import { ClaimsSharedAffiliation } from './ClaimsSharedAffiliation';
import { setMarket } from 'store/actions/appActions';
import ServiceLineUtil from '../utils/ServiceLineUtil';
import { PayorChart } from 'market-data';
import { withReportCriteria } from 'market-data/useReportCriteria';

const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.primary.accent,
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
  tabs: {
    paddingTop: 3,
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.primary.accent,
  },
  indicator: {
    backgroundColor: '#FFFFFF',
    height: 2,
    marginBottom: 8,
  },
  headerDivider: {},
  tabPanel: {
    color: '#E0E0E0',
    '&.Mui-selected': {
      color: '#FFFFFF',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
  },
});

function getTab(report, patientType) {
  let tab = report;
  if (!report || report === 'claims') {
    tab = (
      (patientType ?? PatientType.ALL_PATIENTS).toUpperCase() === PatientType.CAPTURED_CLAIMS
        ? PatientType.CAPTURED_CLAIMS
        : PatientType.ALL_PATIENTS
    ).toLowerCase();
  }
  return tab;
}

class ClaimsTabs extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { dispatch, currentCriteria } = this.props;
    const { patientType, market } = currentCriteria;
    if (patientType) {
      dispatch(setPatientType(patientType.toUpperCase()));
    }

    if (market) {
      dispatch(setMarket(market));
    }
  }

  render() {
    const { classes } = this.props;
    const tab = getTab(
          this.props.currentCriteria.report,
          this.props.currentCriteria.patientType,
    );
    const { currentCriteria } = this.props;
    let tabContent = <></>;
    switch (tab) {
      case 'shared_affiliation':
        tabContent = (
          <ClaimsSharedAffiliation
            query={currentCriteria}
            router={this.props.router}
          />
        );
        break;
      case 'payor':
        tabContent = <PayorChart />;
        break;
      default:
        tabContent = (
          <ClaimsPage query={currentCriteria} router={this.props.router} />
        );
    }

    return (
      <>
        <div className={classes.container}>
          <Helmet defer={false}>
            <title>Market Data</title>
          </Helmet>
          <div className={classes.header}>
            <Typography className={classes.headerTitle}>Market Data</Typography>
          </div>
          <Tabs
            variant='scrollable'
            className={classes.tabs}
            TabIndicatorProps={{ className: classes.indicator }}
            value={tab}
            onChange={this.onTabChange}
          >
            <Tab
              className={classes.tabPanel}
              label='Projected Patients'
              value={PatientType.ALL_PATIENTS.toLowerCase()}
              disableRipple
            />
            <Tab
              className={classes.tabPanel}
              label='Captured Claims'
              value={PatientType.CAPTURED_CLAIMS.toLowerCase()}
              disableRipple
            />
            <Tab
              className={classes.tabPanel}
              label='Shared Affiliation'
              value='shared_affiliation'
              disableRipple
            />
            <Tab
              className={classes.tabPanel}
              label='Payors'
              value='payor'
              disableRipple
            />
          </Tabs>
        </div>
        {tabContent}
      </>
    );
  }

  onTabChange = (_, newValue) => {
    let report;
    let remainder = {};
    switch (newValue) {
      case 'shared_affiliation':
        report = 'shared_affiliation';
        break;
      case 'payor':
        report = 'payor';
        break;
      default:
        let {
          currentCriteria: { serviceline },
        } = this.props;
        if (
          serviceline === 'all' ||
          (Array.isArray(serviceline) && serviceline?.[0] === 'all')
        ) {
          serviceline = ServiceLineUtil.getDefault();
        }
        report = 'claims';
        remainder = {
          serviceline,
          patientType: newValue.toUpperCase(),
        };
    }
    this.props.updateCriteria({ report, ...remainder });
  };
}

ClaimsTabs.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  patientType: PropTypes.oneOf(PatientTypes),
  market: PropTypes.object,
  user: PropTypes.object,
  classes: PropTypes.object,
  updateCriteria: PropTypes.func.isRequired,
  currentCriteria: PropTypes.object.isRequired,
  getCriteria: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    patientType: state.app.patientType,
    market: MarketsUtil.getSelectedMarket(state),
    user: state.user,
  };
}

const styled = withStyles(styles)(withReportCriteria('', ClaimsTabs));
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ClaimsTabs };
