import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { Grid, Stack, Box, Typography, Icon } from '@mui/material';
import { withIsMobile, withRouter } from 'utils';
import { LoadingView } from 'components';
import { StoreUtil } from 'doctivity-shared/utils';
import {
  ListItem,
  ListItemActionButton,
  ListItemLink,
  DetailContainer,
} from './ListItemDetail';
import AnalyticsCard from './AnalyticsCard';
import {
  loadFavoriteContacts,
  loadRecentContacts,
  loadContactActivityNotes,
} from 'store/actions/dashboardActions';
import { ProviderCommentsDialog } from 'components';
import { upsertProviderComment } from 'store/actions/providerCommentsActions';
import { showConfirmationDialog } from 'store/actions/systemActions';

const styles = (theme) => ({
  rowRoot: {
    background: 'linear-gradient(90deg, white 49%, #EBEBEB 50%)',
    cursor: 'pointer',
  },
  rowRootEmpty: {
    cursor: 'unset',
  },
  locationName: {
    color: '#333333',
    fontSize: '14px',
    textWrap: 'nowrap',
    overflow: 'hidden',
    flexGrow: 1,
    flexBasis: '40%',
    textOverflow: 'ellipsis',
    flexBasis: '40%',
  },
  favoriteIcon: {
    fontSize: '16px',
    verticalAlign: 'text-top',
    color: '#E300B8',
    marginRight: '10px',
    marginTop: '2px',
    paddingLeft: '8px',
    width: '16px',
    height: '15px',
  },
  favoriteHeart: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '& > span': {
      fontSize: '72px',
      color: '#E300B8',
      width: '100%',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },

    [theme.breakpoints.down('md')]: {
      '& > span': {
        fontSize: '32px',
      },
    },
  },
  nameContainer: {
    flexBasis: '45%',
    alignContent: 'center',
    textWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    scrollMarginTop: '80px',
  },
  addressText: {
    fontSize: '14px',
    color: '#333',
    fontWeight: 300,
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  noFavorites: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    backgroundColor: '#ebebeb',
    color: '#707070',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Roboto',
    '& > p > h5': {
      color: '#333',
      fontSize: 12,
    },
    fontSize: 12,
    [theme.breakpoints.up('lg')]: {
      paddingTop: '20px',
    },
    display: 'flex',
    flexDirection: 'column',
    height: '25rem',
    justifyContent: 'space-between',
  },
  activityNotesIcon: {
    fontSize: '70px',
    color: '#02E39D',
    marginTop: '5px',
  },
  noFavoritesMessage: {
    fontSize: 14,
    paddingTop: theme.spacing(3),
    width: '100%',
    textAlign: 'center',
  },
});

class DashboardContactsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedContact: 0,
      newNoteDialogOpen: false,
      newNoteForContactId: -1,
    };
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.favoriteContacts)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.app.selectedClient !== prevProps.app.selectedClient) {
      this.fetchData();
    }

    if (
      !StoreUtil.isLoaded(prevProps.favoriteContacts) &&
      StoreUtil.isLoaded(this.props.favoriteContacts)
    ) {
      this.fetchSelected();
    }
    if (this.props.collection !== prevProps.collection) {
      this.fetchData();
      if (this.state.selectedContact !== 0)
        this.setState({ selectedContact: 0 });
    }
    if (
      this.props.editComment !== prevProps.editComment &&
      StoreUtil.isSaved(this.props.editComment)
    ) {
      this.fetchSelected();
    }
  }

  render() {
    const { classes, dashboard, favoriteContacts, isMobile, isEarlyMobile } =
      this.props;

    if (
      !dashboard ||
      !favoriteContacts ||
      !StoreUtil.isLoaded(favoriteContacts)
    ) {
      return <LoadingView />;
    }

    const hasFavorites =
      favoriteContacts && StoreUtil.getData(favoriteContacts).length > 0;
    const renderItem = ({ item: favorite, index, renderDetail }) => {
      const isSelected = this.state.selectedContact === index;
      return (
        <Stack key={favorite?.contact_id} className={classes.rowRoot}>
          {favorite && (
            <ListItem
              isSelected={isSelected}
              style={{
                scrollMarginTop: '80px',
                background:
                  this.state.selectedContact !== index ? '#FFF' : '#EBEBEB',
              }}
              onClick={() => this.selectContact(favorite.contact_id, index)}
              ref={
                index === this.state.selectedContact
                  ? this.scrollRef
                  : undefined
              }
            >
              <Grid
                container
                sx={{
                  height: '100%',
                  flexDirection: 'row-reverse',
                  flexWrap: 'nowrap',
                  gap: 1,
                  marginLeft: '4px',
                  paddingRight: 1,
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
                style={{ paddingLeft: 20 }}
              >
                <Box component={Grid} item className={classes.nameContainer}>
                  <Typography className={classes.addressText}>
                    {`${favorite.city ?? ''}${
                      favorite.state ? ', ' + favorite.state : ''
                    }`}
                  </Typography>
                </Box>
                <div
                  style={{
                    paddingLeft: 8,
                    flexGrow: 1,
                    flexShrink: 1,
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                  }}
                ></div>
                <Typography component='span' className={classes.locationName}>
                  {favorite.last_name}, &nbsp;{favorite.first_name}
                </Typography>
                <Typography component='span'>
                  <Icon className={classes.favoriteIcon}>
                    {this.props.collection === 'favorite' ||
                    favorite.is_favorite
                      ? 'favorite'
                      : 'favorite_border'}
                  </Icon>
                </Typography>
              </Grid>
            </ListItem>
          )}
          {(typeof this.state.selectedContact === undefined ||
            this.state.selectedContact === index) &&
            (isMobile || isEarlyMobile) &&
            renderDetail?.()}
        </Stack>
      );
    };
    return (
      <>
        <Grid
          container
          sx={{
            px: 3,
            py: 2,
          }}
        >
          {!hasFavorites && (
            <Grid
              item
              style={{
                flexGrow: 1,
                maxWidth: 'unset',
                backgroundColor: hasFavorites ? '' : '#ebebeb',
              }}
              xs={isMobile || isEarlyMobile ? 12 : 6}
            >
              <div className={classes.noFavorites}>
                <p>
                  Click the heart icon on Provider, Location, and Contact pages
                  to add them to your favorites.
                </p>
                <span className={classes.favoriteHeart}>
                  <Icon>favorite</Icon>
                </span>
                <Link to={'/contacts'}>
                  See all contacts
                </Link>
              </div>
            </Grid>
          )}

          {hasFavorites && (
            <Grid item xs={isMobile || isEarlyMobile ? 12 : 6}>
              {StoreUtil.getData(favoriteContacts).map((item, index) => {
                return renderItem({
                  item,
                  index,
                  renderDetail: this.renderSelectedContactDetails,
                });
              })}
              {!isMobile && !isEarlyMobile && Array.from({ length: 10 - favoriteContacts.data.length + 1 }).map(
                (_, index) => {
                  return (
                    <Stack key={`blank-${index}`} className={classes.rowRootEmpty}>
                      <Box
                        sx={{
                          height: 42,
                          borderRadius: '5px',
                          borderTopRightRadius: '5px',
                          borderBottomRightRadius: '5px',
                          scrollMarginTop: '80px',
                        }}
                      />
                    </Stack>
                  );
                },
              )}

            </Grid>
          )}
          {!isMobile &&
            !isEarlyMobile &&
            this.renderSelectedContactDetails(this.state.selectedContact)}
        </Grid>
        <Box
          sx={{
            paddingLeft: 1,
            fontSize: '12px',
          }}
        >
          {favoriteContacts?.data.length > 0 && (
            <Link style={{ paddingLeft: '24px' }} to={'/contacts'}>
              See all contacts
            </Link>
          )}
        </Box>
        {this.state.newNoteDialogOpen && (
          <ProviderCommentsDialog
            clientId={this.props.app.selectedClient}
            open={this.state.newNoteDialogOpen}
            contact={favoriteContacts?.data?.[this.state.selectedContact]}
            onSave={this.onSaveNewNote}
            onClose={this.closeNewNoteDialog}
          />
        )}
      </>
    );
  }

  renderSelectedContactDetails = () => {
    const { classes, dashboard, favoriteContacts, clientId } = this.props;
    const { selectedContact } = this.state;

    if (!StoreUtil.isLoaded(favoriteContacts)) {
      return <LoadingView />;
    }
    const favorite = favoriteContacts.data[selectedContact];
    let selectedFavoriteContact = favoriteContacts.data[selectedContact];

    const totalActivityNotes =
      dashboard[
        `analytics-contact-activitynotes-${clientId}-${selectedFavoriteContact?.contact_id}`
      ];
    let totalActivityNotesCount = 0;
    if (StoreUtil.isLoaded(totalActivityNotes)) {
      totalActivityNotesCount = totalActivityNotes.data.total;
    }
    const hasFavorites =
      favoriteContacts &&
      StoreUtil.isLoaded(favoriteContacts) &&
      favoriteContacts?.data?.length > 0;
    const { isMobile } = this.props;
    return (
      <DetailContainer>
        <Grid item xs={12}>
          <Box
            sx={{
              paddingRight: 1,
              textAlign: 'right',
            }}
            gap={'16px'}
            display='flex'
            justifyContent={isMobile ? 'flex-start' : 'flex-end'}
          >
            {favorite && (
              <>
                <ListItemLink
                  component={Link}
                  to={'/contacts/' + favorite?.contact_id}
                >
                  Go to {favorite?.last_name}, &nbsp;{favorite?.first_name}
                </ListItemLink>
                <ListItemActionButton
                  style={{
                    lineHeight: '16px',
                    fontSize: '10px',
                  }}
                  onClick={() => this.openNewNoteDialog(favorite?.contact_id)}
                >
                  + New Note
                </ListItemActionButton>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <AnalyticsCard>
            <Typography
              fontSize='18px'
              color='#333333'
              textAlign='center'
              marginTop='10px'
            >
              Connected Location
            </Typography>
            {!hasFavorites && (
              <Box padding='15px 25px' justifyContent='center' display='flex'>
                <span className={classes.noFavoritesMessage}>
                  No favorite contacts
                </span>
              </Box>
            )}

            {selectedFavoriteContact ? (
              <Box padding='15px 25px' marginTop='15%'>
                <Typography color='#2680E9' fontSize='24px' textAlign='center'>
                  {selectedFavoriteContact.address}
                  {selectedFavoriteContact.address2 && <br />}
                  {selectedFavoriteContact.address2}
                  <br />
                  {selectedFavoriteContact.city}
                  {selectedFavoriteContact.city && ', '}
                  {selectedFavoriteContact.state}
                  <br />
                  {selectedFavoriteContact.postal_code}
                </Typography>
              </Box>
            ) : (
              <Box padding='15px 25px' marginTop='15%'></Box>
            )}
          </AnalyticsCard>
        </Grid>
        <Grid item xs={6}>
          <AnalyticsCard>
            <Typography
              fontSize='18px'
              color='#333333'
              textAlign='center'
              marginTop='10px'
            >
              Total Activity Notes
            </Typography>
            {!hasFavorites && (
              <Box padding='15px 25px' justifyContent='center' display='flex'>
                <span className={classes.noFavoritesMessage}>
                  No favorite contacts
                </span>
              </Box>
            )}
            {hasFavorites && (
              <Box marginTop='25%'>
                <Grid
                  container
                  alignItems='center'
                  justifyContent='center'
                  spacing={1}
                >
                  <Grid item>
                    <Icon className={classes.activityNotesIcon}>
                      speaker_notes
                    </Icon>
                  </Grid>
                  <Grid item>
                    <Typography fontSize='64px'>
                      {totalActivityNotesCount}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </AnalyticsCard>
        </Grid>
      </DetailContainer>
    );
  };

  fetchData() {
    const { favoriteContacts } = this.props;

    if (
      StoreUtil.needsLoadNoCache(favoriteContacts) &&
      !StoreUtil.isLoading(favoriteContacts)
    ) {
      let action = loadFavoriteContacts({
        clientId: this.props.clientId,
      });
      if (this.props.collection !== 'favorite') {
        action = loadRecentContacts({
          clientId: this.props.clientId,
        });
      }
      this.props.dispatch(action);
      if (this.state.selectedContact !== 0)
        this.setState({ selectedContact: 0 });
    }
  }

  fetchSelected() {
    const { dispatch, favoriteContacts, clientId } = this.props;
    let selectedFavoriteContact =
      favoriteContacts.data[this.state.selectedContact];
    if (selectedFavoriteContact) {
      dispatch(
        loadContactActivityNotes({
          contactId: selectedFavoriteContact.contact_id,
          clientId,
        }),
      );
    }
  }

  selectContact = (contactId, index) => {
    this.setState(
      {
        selectedContact: index,
      },
      () => {
        this.fetchSelected();
      },
    );
    this.props.isMobile &&
      setTimeout(() => {
        this.scrollRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 100);
  };

  onSaveNewNote = (data) => {
    const { app, user, dispatch } = this.props;
    data.contact_id = this.state.newNoteForContactId;
    if (
      data.body &&
      data.body.length > 0 &&
      (data.providers.length > 0 ||
        data.contacts.length > 0 ||
        data.location_id)
    ) {
      data.client_id = app.selectedClient;
      data.user_id = user.id;
      data.contacts = data.contacts.filter((p) => p !== undefined);
      data.contacts.push(
        this.props.favoriteContacts.data[this.state.selectedContact].contact_id,
      );

      dispatch(upsertProviderComment(data));
    }
    this.closeNewNoteDialog();
  };

  closeNewNoteDialog = () => {
    this.setState({
      newNoteDialogOpen: false,
      newNoteForContactId: -1,
    });
  };

  openNewNoteDialog = (contactId) => {
    if (this.props.user.is_admin) {
      this.props.dispatch(
        showConfirmationDialog({
          title: 'Admin Users Can Not Create Notes',
          content: 'Login as a non-admin user to create activity notes.',
          confirmLabel: 'OK',
          onConfirm: () => {
            // do nothing
          },
        }),
      );
    } else {
      this.setState({
        newNoteDialogOpen: true,
        newNoteForContactId: contactId,
      });
    }
  };
}

DashboardContactsPage.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isEarlyMobile: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  router: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  dashboard: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  userId: PropTypes.number,
  favoriteContacts: PropTypes.object,
  collection: PropTypes.oneOf(['favorite', 'recent']),
  editComment: PropTypes.object,
};

function mapStateToProps(state, props) {
  const { app, user, dashboard, providerComments } = state;
  const userId = state.user ? state.user.id : null;
  const clientId = state.app.selectedClient;

  return {
    app,
    user,
    userId,
    clientId,
    dashboard,
    editComment: StoreUtil.get(providerComments, StoreUtil.COMMON_EDIT_ITEM),
    favoriteContacts: StoreUtil.get(
      dashboard,
      `${props.collection}-contacts-${clientId}`,
    ),
  };
}

const withStyled = withStyles(styles)(withIsMobile(DashboardContactsPage));
const connected = connect(mapStateToProps)(withStyled);
const routed = withRouter(connected);
export { routed as DashboardContactsPage };
