import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'utils';
import { StoreUtil } from 'doctivity-shared/utils';
import {
  queryDocuments,
} from 'store/actions/documentsActions';
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Dropzone from 'react-dropzone';
import {
  LoadingView,UploadTabs
} from 'components';


const styles = (theme) => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  formItem: {
    marginTop: theme.spacing(2),
  },
  columns: {
    fontSize: 11,
    fontWeight: 300,
  },
  dropzone: {
    border: '1px solid #aaaaaa',
    borderRadius: 5,
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  browseButton: {
    marginLeft: 20,
  },
});

class DocumentDialogWithLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        where: { },
        offset: 0,
        limit: 10000,
        order: [
          ['created_at', 'DESC'],
        ],
      },
      selectedFile: null,
      name: null,
      description: null,
      tab: 'upload_document',
      title: 'Attach Document',
      documentId: '',
      documents: []
    };
  }

  newTab = (newTab) => {
    this.setState({
     tab: newTab,
     title: newTab==='upload_document' ? 'Upload Document' : 'Link Document'
    });

    if (newTab=='link_document') {
      this.fetchData();
    }
  }

  resetState() {
    this.setState({
      selectedFile: null,
      name: null,
      description: null,
      documentId: 0
    });
  }

  render() {
    const {
      classes,
      open,
      uploadState,
      editing,
    } = this.props;

    const {
      selectedFile,
      name,
      description,
      documentId,
    } = this.state;

    let fileName = name;
    if (fileName == null) {
      if (selectedFile) {
        fileName = selectedFile.name;
      } else if (editing) {
        fileName = editing.name;
      }
    }

    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth='sm'
        TransitionProps={{
          onExited: this.handleUploadExited,
        }}
      >
        <UploadTabs newTab={ this.newTab } tab={this.state.tab} title={this.state.title} />
        
        <DialogContent>
          { this.state.tab==='upload_document' && 
          <form className={classes.form} noValidate autoComplete='off'>
              <Dropzone onDrop={this.selectFile} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    {
                      uploadState === 'EDIT'
                      && (
                        <div {...getRootProps()} className={classes.dropzone}>
                          <input {...getInputProps()} />
                          {
                            !selectedFile
                            && (
                              <Typography style={{ cursor: 'pointer' }}>
                                Drag and drop the file here or
                                <Button variant='outlined' className={classes.browseButton}>Browse Files</Button>
                              </Typography>
                            )
                          }
                          {
                            selectedFile
                            && (
                              <Typography color='primary' style={{ cursor: 'pointer' }}>
                                {selectedFile.name}
                              </Typography>
                            )
                          }
                        </div>
                      )
                    }
                    {
                      (uploadState === 'LOADING')
                      && <LoadingView label={`${this.props.uploadProgress}% Uploaded`} />
                    }
                    {
                      uploadState === 'SUCCESS'
                      && (
                        <Typography>
                          Your file was successfully uploaded.
                          It could take a few more minutes before it is available to everyone.
                        </Typography>
                      )
                    }
                    {
                      uploadState === 'ERROR'
                      && (
                        <Typography>
                          There was a problem uploading your file. Please try again.
                        </Typography>
                      )
                    }
                  </section>
                )}
              </Dropzone>
            {
              uploadState === 'EDIT'
              && (
                <>
                  <FormControl key='name' className={classes.formItem}>
                    <TextField
                      label='Name'
                      variant='outlined'
                      value={fileName || ''}
                      inputProps={{ maxLength: 50 }}
                      onChange={(e) => { this.setState({ name: e.target.value }); }}
                    />
                  </FormControl>
                  <FormControl key='description' className={classes.formItem}>
                    <TextField
                      label='Description'
                      multiline
                      rows={3}
                      variant='outlined'
                      inputProps={{ maxLength: 510 }}
                      value={description || (editing && editing.description) || ''}
                      onChange={(e) => { this.setState({ description: e.target.value }); }}
                    />
                  </FormControl>
                </>
              )
            }
          </form>}
          { this.state.tab==='link_document' && 
            <form className={classes.form} noValidate autoComplete='off'>
                <FormControl fullWidth>
                  <InputLabel id='type-label'>Documents</InputLabel>
                  <Select
                    labelId='type-label'
                    id='type-select'
                    value={documentId || ''}
                    label='Document'
                    onChange={this.onDocumentChange}
                  >
                    {this.props.documents.data?.rows.map((t) => (
                      <MenuItem name={t.name} value={t.id} key={t.id} obj={t}>
                        {t.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </form>} 


        </DialogContent>
        <DialogActions>
          {uploadState === 'EDIT'
            && (
              <>
                <Button onClick={this.onClose} color='primary'>
                  Cancel
                </Button>
                {this.state.tab==='upload_document' && 
                  <Button variant='contained' onClick={this.startUpload} color='primary' disabled={!this.state.selectedFile && !editing}>
                    { editing ? 'Save' : 'Upload' }
                  </Button>}
                {this.state.tab==='link_document' && 
                    <Button variant='contained' color='primary' onClick={this.linkDocument} disabled={!documentId}>
                    Link
                  </Button>}
              </>
            )}
          {
            uploadState === 'SUCCESS'
            && (
              <Button onClick={this.onClose} color='primary'>
                Close
              </Button>
            )
          }
          {
            uploadState === 'ERROR'
            && (
              <Button onClick={this.onClose} color='primary'>
                Close
              </Button>
            )
          }
        </DialogActions>
      </Dialog>
    );
  }

  onDocumentChange = (event) => {
    this.setState({
      documentId: event.target.value,
      dataModified: true,
      selectedFile: event.target.name,
    });
  };

  fetchData() {
    const {
      dispatch,
      app,
    } = this.props;
    const { query } = this.state;

    query.where.client_id = app.selectedClient;
    dispatch(queryDocuments(query));
  }

  handleUploadExited = () => {
    this.setState({
      selectedFile: null,
    });
    this.props.onExited();
  };

  selectFile = (files) => {
    this.setState({
      selectedFile: files[0],
    });
  };

  linkDocument = () => {

    let file = this.props.documents.data.rows.find((i) => i.id === this.state.documentId);


    this.props.onLink({
      file: file,
      //{
        //name: this.state.selectedFile.value
      //},
      docId: this.state.documentId,
      client_id: this.props.clientId,
    })
    this.onClose();
  }

  startUpload = () => {
    if (this.state.selectedFile) {

      this.props.onUpload({
        file: this.state.selectedFile,
        name: this.state.name,
        description: this.state.description,
        file: this.state.selectedFile,
        client_id: this.props.clientId,
      })
      this.resetState();
    }
  };

  onClose = () => {
    this.resetState();
    this.props.onClose();
  };


}

DocumentDialogWithLink.propTypes = {
  app: PropTypes.object.isRequired,
  documents: PropTypes.object,
  dispatch: PropTypes.func,
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  editing: PropTypes.object,
  uploadState: PropTypes.string.isRequired,
  uploadProgress: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
  onLink: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { app, documents } = state;

  return {
    app,
    documents: StoreUtil.get(documents, StoreUtil.COMMON_TABLE),
  };
}

const c = withStyles(styles)(DocumentDialogWithLink);
const connected = connect(mapStateToProps)(c);
const routed = withRouter(connected);
export { routed as DocumentDialogWithLink };